import React from 'react';
import Loadable from 'react-loadable';
import config from '../../config';
import Layout from '../components/Layout';
import Header from '../components/Header';
import indexModule from './index.module.scss';

function IndexPage() {
  const LoadingDiv = () => <div>Loading...</div>;
  const Team = Loadable({
    loader: () => import('../subpages/Team/Team'),
    loading: LoadingDiv,
  });
  const Refugies = Loadable({
    loader: () => import('../subpages/Refuges/Refuges'),
    loading: LoadingDiv,
  });
  const ContactUs = Loadable({
    loader: () => import('../components/ContactUs/ContactUs'),
    loading: LoadingDiv,
  });
  const Footer = Loadable({
    loader: () => import('../components/Footer/Footer'),
    loading: LoadingDiv,
  });
  return (
    <Layout>
      <Header />

      <header className="masthead">
        <div className="container d-flex h-100 align-items-center">
          <div className="mx-auto text-center">
            <h1 className="mx-auto my-0 text-uppercase">{config.heading}</h1>
            <h2 className="text-white-50 mx-auto mt-2 mb-5">
              {config.subHeading}
            </h2>
          </div>
        </div>
      </header>

      <Refugies />
      <Team />
      <ContactUs />

      {/*<SocialLinks />/*/}
      <Footer />
    </Layout>
  );
}

export default IndexPage;
